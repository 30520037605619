<template>
  <div>
    <!-- Invoice Form -->
    <div class="container my-4">
      <h2>Generate Invoice</h2>
      <div class="ibox-content">
        <form @submit.prevent="generateInvoice">
          <div class="row">
            <!-- From Date -->
            <div class="col-lg-4 mb-3">
              <label for="fromDate" class="form-label">From Date</label>
              <input
                type="date"
                id="fromDate"
                v-model="invoiceData.fromDate"
                class="form-control"
                required
              />
            </div>
            <!-- To Date -->
            <div class="col-lg-4 mb-3">
              <label for="toDate" class="form-label">To Date</label>
              <input
                type="date"
                id="toDate"
                v-model="invoiceData.toDate"
                class="form-control"
                required
              />
            </div>
            <!-- CRO Name -->
            <div class="col-lg-4 mb-3">
              <label for="croName" class="form-label">CRO Name</label>
              <input
                type="text"
                id="croName"
                v-model="invoiceData.croName"
                class="form-control"
                placeholder="Enter CRO Name"
                required
              />
            </div>
            <!-- Client Name -->
            <div class="col-lg-6 mb-3">
              <label for="clientName" class="form-label">Client Name</label>
              <input
                type="text"
                id="clientName"
                v-model="invoiceData.clientName"
                class="form-control"
                placeholder="Enter Client Name"
                required
              />
            </div>
            <!-- Service -->
            <div class="col-lg-6 mb-3">
              <label for="service" class="form-label">Service</label>
              <input
                type="text"
                id="service"
                v-model="invoiceData.service"
                class="form-control"
                placeholder="Enter service provided"
                required
              />
            </div>
            <!-- Rate -->
            <div class="col-lg-4 mb-3">
              <label for="rate" class="form-label">Rate (per hour/day)</label>
              <input
                type="number"
                id="rate"
                v-model="invoiceData.rate"
                class="form-control"
                placeholder="Enter rate"
                min="1"
                required
              />
            </div>
            <!-- Duration -->
            <div class="col-lg-4 mb-3">
              <label for="duration" class="form-label">Duration (in hours/days)</label>
              <input
                type="text"
                id="duration"
                v-model="invoiceData.duration"
                class="form-control"
                placeholder="e.g., 40 hours"
                required
              />
            </div>
            <!-- Total Amount -->
            <div class="col-lg-4 mb-3">
              <label for="totalAmount" class="form-label">Total Amount (in USD)</label>
              <input
                type="number"
                id="totalAmount"
                v-model="invoiceData.totalAmount"
                class="form-control"
                placeholder="Enter amount"
                min="1"
                required
              />
            </div>
            <div class="col-lg-12 mb-1">
              <button type="submit" class="btn btn-primary px-4 py-1 float-right">
                Generate Invoice
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- Invoice Popup -->
    <div v-if="showInvoice" class="invoice-popup">
      <div class="popup-mask"></div>
      <div class="popup-content">
        <div class="cursor-pointer float-right" @click="closeInvoice">
          <i class="fa fa-times"></i>
        </div>
        <div class="invoice-content p-4">
          <div class="row mb-4">
            <div class="col-sm-6">
              <img src="../../assets/img/tnt_logo.png" alt="TNT Logo" />
              <address>
                5267 TNT Street, Suite 002,<br />
                Texas, MA 02113, USA<br />
                <abbr title="Phone">P:</abbr> +1 (627) 123-1234
              </address>
            </div>
            <div class="col-sm-6 text-right">
              <p><strong>Invoice No:</strong> #53745</p>
              <p><strong>Invoice Date:</strong> {{ new Date().toLocaleDateString() }}</p>
              <p><strong>From Date:</strong> {{ invoiceData.fromDate }}</p>
              <p><strong>To Date:</strong> {{ invoiceData.toDate }}</p>
            </div>
          </div>

          <table class="table invoice-table">
            <thead>
              <tr>
                <th>Service</th>
                <th>Rate</th>
                <th>Duration</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ invoiceData.service }}</td>
                <td>${{ invoiceData.rate }}</td>
                <td>{{ invoiceData.duration }}</td>
                <td>${{ invoiceData.totalAmount }}</td>
              </tr>
            </tbody>
          </table>

          <p><strong>Payment Terms:</strong> Net 30</p>

          <!-- Actions -->
          <div class="d-flex justify-content-between mt-4 hide-for-pdf">
            <button class="btn btn-success" @click="downloadPDF">Download PDF</button>
            <button class="btn btn-primary" @click="printInvoice">Print</button>
            <button class="btn btn-warning" @click="shareInvoice">Share</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      invoiceData: {
        fromDate: "",
        toDate: "",
        croName: "",
        clientName: "",
        service: "",
        rate: "",
        duration: "",
        totalAmount: "",
      },
      showInvoice: false,
    };
  },
  methods: {
    generateInvoice() {
      this.showInvoice = true;
    },
    closeInvoice() {
      this.showInvoice = false;
    },
    downloadPDF() {
      const content = document.querySelector(".invoice-content");
      const actions = document.querySelector(".hide-for-pdf");
      actions.style.display = "none";

      html2canvas(content, { scale: 2 })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");

          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          pdf.save("invoice.pdf");

          actions.style.display = "flex";
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
          actions.style.display = "flex";
        });
    },
    printInvoice() {
      window.print();
    },
    shareInvoice() {
      alert("Share functionality is not implemented yet.");
    },
  },
};
</script>

  
  <style>
  .invoice-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 9999;
  overflow-y: auto;
}

.popup-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1049;
}

.popup-content {
  background: white;
  max-width: 80%;
  max-height: auto;
  width: 210mm;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow-y: auto;
  margin-top: 20px;
}

.invoice-content {
  font-family: Arial, sans-serif;
  font-size: 12px;
}
.hide-for-pdf {
  display: none !important;
}
@media print {
  body * {
    visibility: hidden;
  }

  .invoice-popup, .invoice-popup * {
    visibility: visible; 
  }

  .invoice-popup {
    position: static;
    overflow: visible;
  }

  .popup-content {
    margin: 0;
    width: 100%;
    height: auto; 
    box-shadow: none; 
  }
}

  </style>
  